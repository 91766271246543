import React, { useState } from 'react'
import Wallet from '../Wallet';
import { Link } from 'react-router-dom';

export default function PhantomModal() {
    const [nftCollection, setNftCollection] = useState(null);

    const renderNFTModal = () => {
        return (
            <div className='modal-overlay'>
                <div className='modal-container'>
                    <h3>Exchange NFTs</h3>
                    <Wallet setNftCollection={handleSetNftCollection} />
                    {renderNFTGallery()}
                </div>
            </div>
        )
    }

    const handleSetNftCollection = (nfts) => {
        setNftCollection(nfts?.length ? nfts : null)
    }

    const renderNFTGallery = () => {
        if (nftCollection?.length) {
            return (
                <div className='collection'>
                    <div className='titles-container'>
                        <div className='title'>
                            My NFT Collection
                        </div>
                    </div>

                    <div className='gallery-container'>
                        {
                            nftCollection.map((element, index) => {
                                return renderGalleryElement(element, index)
                            })
                        }
                    </div>
                </div>
            )
        }
    }

    const renderGalleryElement = (element, index, placeholder) => {
        return (
            <Link to={'#'} className='element' style={{backgroundImage: `url(${element?.thumbnail || element?.profilePicture || element?.imageUrl || placeholder})`, animationDuration: index / 10 + 's' }} key={index}>
                <div className='title-card'>{element?.name || element?.userName}</div>
                {
                    element?.size && element?.size !== '' &&
                    <div className='badge'>
                        {element?.size}
                    </div>
                }
            </Link>
        )
    }

    return renderNFTModal()
}
