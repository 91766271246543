import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import axios from "axios";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageType: "LOGIN",
            login: "",
            password: "",
            isPwdHidden: true,
            isChecked: false,
            initialValidated: false,
            otp: "",
            isResendDisplayed: true,
        };
    }

    handleSwitch = (type) => {
        this.setState({
            pageType: type,
        });
    };

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    toggleHiddenPwd = () => {
        this.setState({
            isPwdHidden: !this.state.isPwdHidden,
        });
    };

    handleRememberMe = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    };

    switchSingIn = (e) => {
        e.preventDefault();
        if (this.state.isLoading) return;

        if (this.props.isPrivate) return this.handleSignInAdmin();

        return this.handleSignIn();
    };

    handleSignInAdmin = () => {
        if (!this.state.initialValidated) {
            axios
                .post(`https://versemail.azurewebsites.net/auth/login/`, {
                    login: this.state.login,
                    password: this.state.password,
                })
                .then((response) => {
                    if (response) {
                        this.setState({
                            initialValidated: true,
                        });
                    } else {
                        alert("error with your credentials");
                    }
                })
                .catch((error) => {
                    alert("error with your credentials");
                });
        } else {
            axios
                .post(`https://versemail.azurewebsites.net/auth/login/verify`, {
                    login: this.state.login,
                    password: this.state.password,
                    otp: this.state.otp,
                })
                .then((response) => {
                    if (response?.data?.accessToken) {
                        localStorage.setItem("user", JSON.stringify(response.data));
                        window.open("/news", "_self");
                    } else {
                        alert("OTP not valid or error with your credentials");
                    }
                })
                .catch((error) => {
                    alert("OTP not valid or error with your credentials");
                });
        }
    };

    sendOTP = (e) => {
        e.preventDefault();

        axios
            .post(`https://versemail.azurewebsites.net/auth/login/`, {
                login: this.state.login,
                password: this.state.password,
            })
            .then((response) => {
                if (response) {
                    this.setState({
                        otp: "",
                        isResendDisplayed: false,
                    });
                } else {
                    alert("error with your credentials");
                }
            })
            .catch((error) => {
                alert("error with your credentials");
            });
    };

    setOtp = (value) => {
        this.setState({
            otp: value,
        });
    };

    renderOTP = (initialValidated) => {
        if (initialValidated)
            return (
                <div className="otp-container">
                    <OtpInput
                        value={this.state.otp}
                        onChange={this.setOtp}
                        numInputs={4}
                        separator={<span>-</span>}
                        shouldAutoFocus={true}
                    />

                    {this.state.isResendDisplayed ? (
                        <a
                            href="#"
                            className="text-explainer"
                            onClick={(e) => this.sendOTP(e)}
                        >
                            Send another OTP code
                        </a>
                    ) : (
                        <label>New code sent</label>
                    )}
                </div>
            );
    };

    renderForgotPassword = () => {
        return (
            <div className="forgot-password-container">
                <div className="remember-me" onClick={this.handleRememberMe}>
                    <i
                        class={
                            !this.state.isChecked
                                ? "bx bx-checkbox checkbox"
                                : "bx bxs-checkbox-checked checkbox checked"
                        }
                    ></i>
                    <p>Remember me</p>
                </div>

                <Link to={'/start/reset'} className="forgot-password">Forgot Password ?</Link>
            </div>
        );
    };

    handleSignIn = () => {
        if (this.state.login === "" || this.state.password === "") {
            this.setState({
                error: "Please enter your credentials",
            });

            return;
        }

        this.setState({
            isLoading: true,
        });
        axios
            .post(`https://versemail.azurewebsites.net/playfab/auth`, {
                userName: this.state.login,
                password: this.state.password,
                version: 100000
            })
            .then((response) => {
                if (response?.data?.accessToken) {
                    if (this.state.isChecked) {
                        localStorage.setItem("user", JSON.stringify(response.data));
                    } else {
                        sessionStorage.setItem("user", JSON.stringify(response.data));
                    }
                    window.open("/profile/" + response.data?.userName, "_self");
                } else if (response?.data?.userNotVerified) {
                    this.setState({
                        error:
                            "You have not verified your email. You will be redirected to the verification page shortly...",
                    });

                    setTimeout(() => {
                        window.open("/start/verify/" + response?.data?.token, "_self");
                    }, 5000);
                }

                this.setState({
                    isLoading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    error: "Incorrect user name or password.",
                    isLoading: false,
                });
            });
    };

    renderError = () => {
        if (this.state.error) {
            return <div className="status-bar alert">{this.state.error}</div>;
        }
    };

    render() {
        return (
            <div className="login">
                <h4>Welcome to Verse Estate..!</h4>
                <div className="switcher">
                    <div
                        className={this.state.pageType === "LOGIN" ? "active" : ""}
                        onClick={() => this.handleSwitch("LOGIN")}
                    >
                        Login
                    </div>
                    <Link
                        to={this.props.isPrivate ? "/ctqbc/register" : "/start/register"}
                        className={this.state.pageType === "REGISTER" ? "active" : ""}
                        onClick={() => this.handleSwitch("REGISTER")}
                    >
                        Register
                    </Link>
                </div>

                <div className="text-explainer">
                    {this.props.isPrivate
                        ? "Enter your login and password, then verify your identity with OTP."
                        : "Enter your login and password to access to the Verse World."}
                </div>

                {this.renderError()}

                <form className="form" onSubmit={this.switchSingIn} noValidate>
                    <div className="input-container">
                        <label>User name</label>
                        <input
                            type="text"
                            placeholder="Enter your User name"
                            name="login"
                            onChange={this.handleInput}
                            value={this.state.login}
                            required
                        />
                    </div>

                    <div className="input-container">
                        <label>Password</label>
                        <div className="password-container">
                            <input
                                type={this.state.isPwdHidden ? "password" : "text"}
                                placeholder="Enter your Password"
                                name="password"
                                onChange={this.handleInput}
                                value={this.state.password}
                                required
                            />
                            <i
                                class={this.state.isPwdHidden ? "bx bxs-hide" : "bx bxs-show"}
                                onClick={this.toggleHiddenPwd}
                            />
                        </div>
                    </div>

                    {this.renderForgotPassword()}
                    {this.renderOTP(this.state.initialValidated)}
                    <div className={"login-btn-container"}>
                        <input
                            type="submit"
                            className={"login-btn" + (this.state.isLoading ? " loading" : "")}
                            value={"Login"}
                        />
                    </div>
                </form>
            </div>
        );
    }
}
